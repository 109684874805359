<template>
    <div class="list-page">
        <div class="inner">
            <div class="header">
                <div class="title"></div>
                <div class="button-group">
                    <button-wrapper
                        type="modal"
                        color="normal"
                        :disabled="!isSelectionChecked || isAbleOffShelf"
                        @click="onBulkOffShelf"
                    >
                        下架案例
                    </button-wrapper>
                    <button-wrapper
                        type="modal"
                        color="normal"
                        @click="onGetExcelList"
                    >
                        下載excel
                    </button-wrapper>
                    <button-wrapper
                        type="modal"
                        color="danger"
                        :disabled="!isSelectionChecked"
                        @click="onBulkDelete"
                    >
                        刪除
                    </button-wrapper>
                </div>
            </div>
            <div class="display-section">
                <div class="toolbar">
                    <div class="filter">
                        <div
                            v-for="state in states"
                            :key="state.value"
                            @click="onFilterChange(state.value)"
                            :class="{ active: filterState === state.value }"
                        >
                            {{ state.label }}
                        </div>
                    </div>
                    <div class="hcp-search">
                        <el-select
                            v-model="searchCategory"
                            placeholder="搜尋分類"
                            @change="onChangeSearchCategory"
                        >
                            <el-option
                                v-for="item in searchList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="keyword-search">
                        <el-input
                            v-model="searchKeyword"
                            :disabled="!searchCategory"
                            placeholder="搜尋關鍵字"
                            suffix-icon="el-icon-search"
                            @change="onTextFilterChange"
                        ></el-input>
                    </div>
                </div>
                <el-table
                    empty-text="暫無資料"
                    :data="list"
                    height="85%"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column fixed type="selection" width="55">
                    </el-table-column>
                    <el-table-column
                        fixed
                        type="index"
                        label="編號欄位"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        fixed
                        prop="state"
                        label="狀態"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <span :class="`state ${scope.row.state}`">{{
                                stateDisplay(scope.row.state)
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="account.name"
                        label="PJP"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="doctorName"
                        label="醫師姓名"
                        width="120"
                    >
                    </el-table-column>
                    <el-table-column prop="title" label="標題欄位" width="200">
                    </el-table-column>
                    <el-table-column prop="date" label="日期" width="160">
                        <template slot-scope="scope">
                            {{
                                scope.row.date
                                    ? formatDate(scope.row.date)
                                    : '尚未填入日期'
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="veeva"
                        label="Veeva code"
                        width="170"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="viewCount"
                        label="點擊次數"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column fixed="right" width="180">
                        <template slot-scope="scope">
                            <div class="button-group">
                                <div
                                    v-if="checkState(scope.row.state).editable"
                                    class="manager-edit-button"
                                    @click="onEdit(scope.row.id)"
                                >
                                    編輯與審核
                                </div>
                                <div
                                    v-else
                                    class="manager-edit-button disabled"
                                ></div>
                                <div
                                    class="manager-normal-button"
                                    @click="onPreview(scope.row.id)"
                                >
                                    瀏覽
                                </div>
                                <div
                                    class="manager-delete-button"
                                    @click="onDelete(scope.row.id)"
                                >
                                    刪除
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <infinite-loading
                        slot="append"
                        :identifier="infiniteId"
                        @infinite="handleInfinite"
                        force-use-infinite-wrapper=".el-table__body-wrapper"
                    >
                    </infinite-loading>
                </el-table>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from 'vuex';
import XLSX from '@/models/xlsx';

export default {
    data() {
        return {
            searchKeyword: '',
            searchCategory: '',
            multipleSelection: [],
            loading: false,
            states: [
                {
                    label: 'All',
                    value: undefined,
                },
                {
                    label: '草稿',
                    value: 'draft',
                },
                {
                    label: '已儲存',
                    value: 'doctor-save',
                },
                {
                    label: '已送出',
                    value: 'doctor-send',
                },
                {
                    label: '待審核',
                    value: 'review',
                },
                {
                    label: '未通過',
                    value: 'reject',
                },
                {
                    label: '審核完畢',
                    value: 'approve',
                },
                {
                    label: '已上前端',
                    value: 'display',
                },
            ],
            searchList: [
                {
                    label: '醫師名稱',
                    value: 'doctorName',
                },
                {
                    label: '標題',
                    value: 'title',
                },
                {
                    label: 'PJP名稱',
                    value: 'salesName',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            list: (state) => state.manager.caseList,
            infiniteId: (state) => state.manager.infiniteId,
            filterState: (state) => state.manager.filterState.value,

            excelLabelConfig: (state) => state.config.excelLabel,
        }),
        isSelectionChecked() {
            return this.multipleSelection.length !== 0;
        },
        isAbleOffShelf() {
            return !!this.multipleSelection.find(
                (item) => item.state !== 'display',
            );
        },
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapMutations({
            setMode: 'form/setMode',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',

            updateFilterState: 'manager/updateFilterState',
            updateFilterTextLabel: 'manager/updateFilterTextLabel',
            updateFilterText: 'manager/updateFilterText',
            increasePage: 'manager/increasePage',
            clear: 'manager/clear',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',

            fetchQuery: 'manager/fetchQuery',
            updateFilter: 'manager/updateFilter',
            onSearch: 'manager/onSearchCase',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async handleInfinite($state) {
            try {
                this.loading = true;
                this.fetchQuery();
                const RES = await this.onSearch();
                this.increasePage();
                if (RES) $state.loaded();
                else $state.complete();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        stateDisplay(state) {
            switch (state) {
                case 'draft':
                    return '草稿中';
                case 'doctor-save':
                    return '已儲存';
                case 'doctor-send':
                    return '已送出';
                case 'review':
                    return '待審核';
                case 'reject':
                    return '未通過';
                case 'approve':
                    return '審核完畢';
                case 'display':
                    return '已上前端';
                default:
                    return '';
            }
        },
        checkState(state) {
            switch (state) {
                case 'display':
                    return { editable: false };
                case 'draft':
                case 'doctor-save':
                case 'reject':
                case 'doctor-send':
                case 'review':
                case 'approve':
                default:
                    return { editable: true };
            }
        },
        onEdit(index) {
            this.$router.push({
                name: 'Manager_Form',
                params: { id: index },
            });
        },
        onDelete(index) {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要刪除此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'delete',
                    successMsg: '案例已刪除',
                    data: index,
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onBulkOffShelf() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: `確定下架這${this.multipleSelection.length}筆案例嗎？`,
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const DATA = [];
                this.multipleSelection.forEach((item) => {
                    DATA.push(item.id);
                });
                const PAYLOAD = {
                    category: 'Case',
                    function: 'bulkOffShelf',
                    successMsg: '案例已下架',
                    data: { ids: DATA },
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onGetExcelList() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: this.multipleSelection.length
                    ? `確定下載${this.multipleSelection.length}筆案例Excel嗎？`
                    : '確定下載所有案例Excel嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const DATA = [];
                this.multipleSelection.forEach((item) => {
                    DATA.push(item.id);
                });
                const PAYLOAD = {
                    category: 'Case',
                    function: 'getExcelList',
                    successMsg: '下載已開始',
                    data: { ids: DATA },
                };
                const RES = await this.apiClient(PAYLOAD);
                XLSX(RES.data, this.excelLabelConfig);
            });
        },
        onBulkDelete() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: `確定要刪除這${this.multipleSelection.length}筆案例嗎？`,
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const DATA = [];
                this.multipleSelection.forEach((item) => {
                    DATA.push(item.id);
                });
                const PAYLOAD = {
                    category: 'Case',
                    function: 'bulkDelete',
                    successMsg: '案例已刪除',
                    data: { ids: DATA },
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onPreview(index) {
            this.setMode('VIEW');
            this.$router.push({
                name: 'Manager_Form',
                params: { id: index },
            });
        },
        onFilterChange(state) {
            if (this.loading) return;
            this.updateFilterState(state);
            this.updateFilter();
        },
        onChangeSearchCategory(data) {
            this.updateFilterTextLabel(data);
        },
        onTextFilterChange(text) {
            this.updateFilterText(text);
            this.updateFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
.state {
    padding: 5px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
}
.button-group {
    display: flex;
    justify-content: space-between;
    > * {
        cursor: pointer;
    }
    .manager-edit-button {
        width: 70px;
        &.disabled {
            cursor: default;
        }
    }
}
</style>
