import _ from 'lodash';
import XLSX from 'xlsx';

function handleArrayData(DATA, CONFIG) {
    if (DATA[0] && typeof DATA[0] === 'object') {
        const CHILDREN = CONFIG.children;
        const FLAT = [];
        DATA.forEach((item) => {
            const TEXT = [];
            _.forEach(item, (value, key) => {
                const FOUND = _.find(CHILDREN, { fieldName: key });
                TEXT.push(`${value} ${FOUND.suffix || ''}`);
            });
            FLAT.push(TEXT.join(CONFIG.column ? '\n' : ' '));
        });
        return FLAT.join(CONFIG.column ? '\n\n' : '\n');
    }
    return DATA.join(', ');
}

function handleJSONData(DATA, CONFIG) {
    // handle input-boolean-field
    if (DATA.value) {
        const FLAT = [];
        FLAT.push(DATA.value);
        if (DATA.boolean) FLAT.push(CONFIG.booleanLabel);
        return FLAT.join('\n');
    }

    if (DATA) {
        const FLAT = [];
        _.forEach(DATA, (value) => {
            const ROW = [];
            if (Array.isArray(value)) {
                _.forEach(value, (childValue) => {
                    ROW.push(childValue);
                });
                FLAT.push(ROW.join(', '));
            } else {
                FLAT.push(value);
            }
        });
        return FLAT.join('\n');
    }
    return '';
}

function getHeader(config) {
    const HEADER = [];
    const EXAMPLE = {};
    config.forEach((item) => {
        HEADER.push(item.fieldName);
        EXAMPLE[item.fieldName] = '';
    });

    return HEADER;
}

export default function fetchExcel(DATASET, ExcelLabel) {
    const WB = XLSX.utils.book_new();
    const SHEET_ROW = [];
    DATASET.forEach((DATA) => {
        const TEMP = {};
        if (DATA.data.data) {
            _.forEach(DATA.data.data, (value, key) => {
                const CONFIG = _.find(ExcelLabel, { fieldName: key });
                if (CONFIG && value !== null && value !== undefined) {
                    if (Array.isArray(value)) {
                        TEMP[key] = handleArrayData(value, CONFIG);
                    } else if (typeof value === 'object') {
                        TEMP[key] = handleJSONData(value, CONFIG);
                    } else TEMP[key] = value + (CONFIG.suffix || '');
                }
            });
        }
        SHEET_ROW.push(TEMP);
    });
    const HEADER_LABEL = {};
    const HEADER = getHeader(ExcelLabel);
    ExcelLabel.forEach((item) => {
        HEADER_LABEL[item.fieldName] = item.label;
    });
    const WS = XLSX.utils.json_to_sheet([HEADER_LABEL], { header: HEADER, skipHeader: true });
    XLSX.utils.sheet_add_json(WS, SHEET_ROW, { header: HEADER, skipHeader: true, origin: -1 });
    XLSX.utils.book_append_sheet(WB, WS);
    XLSX.writeFile(WB, 'Case.xlsx');
}
